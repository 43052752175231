import { useFormik } from "formik"
import * as yup from "yup"
import { defineMessages, useIntl } from "gatsby-plugin-intl"
import sdk from "@sdk"
import { useState } from "react"

const errorMessages = defineMessages({
  emailFormat: {
    defaultMessage: "This email is not in a correct format",
    id: "hooks.useContactForm.emailFormat",
  },
  emailRequired: {
    defaultMessage: "The email is required",
    id: "hooks.useContactForm.emailRequired",
  },
  messageMaxLength: {
    defaultMessage: "Message max length is 500",
    id: "hooks.useContactForm.messageMaxLength",
  },
  messageMinLength: {
    defaultMessage: "Message min length is 5",
    id: "hooks.useContactForm.messageMinLength",
  },
  nameRequired: {
    defaultMessage: "Your name is required",
    id: "hooks.useContactForm.nameRequired",
  },
})

function getInitialValues() {
  return {
    email: "",
    message: "",
    name: "",
  }
}

export default function useContactForm() {
  const intl = useIntl()
  const [sent, setSent] = useState(false)
  const validationSchema = yup.object({
    message: yup
      .string()
      .required(intl.formatMessage(errorMessages.messageMinLength))
      .max(500, intl.formatMessage(errorMessages.messageMaxLength))
      .min(5, intl.formatMessage(errorMessages.messageMinLength))
      .trim(),
    email: yup
      .string()
      .required(intl.formatMessage(errorMessages.emailRequired))
      .trim()
      .email(intl.formatMessage(errorMessages.emailFormat)),
    name: yup
      .string()
      .required(intl.formatMessage(errorMessages.nameRequired))
      .trim(),
  })
  return {
    form: useFormik({
      initialValues: getInitialValues(),
      onSubmit: async (values, helpers) => {
        helpers.setSubmitting(true)

        await sdk.contacts.subscribe(values)

        setSent(true)
        helpers.setSubmitting(false)
      },
      validationSchema,
    }),
    sent,
  }
}
