import { Actor } from "@definitions/Actor"
import useCssUtil from "@hooks/use-css-util"
import { Typography } from "@material-ui/core"
import React, { FC } from "react"

export interface BioDetailEntryListProps {
  actor: Actor
}

export const BioDetailEntryList: FC<BioDetailEntryListProps> = ({ actor }) => {
  const cssUtil = useCssUtil()

  return (
    <>
      {actor.longbioparagraphs.map((entry, index) => (
        <Typography variant="body1" className={cssUtil.mb20} key={index}>
          {entry}
        </Typography>
      ))}
    </>
  )
}
