import { Actor } from "@definitions/Actor"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { option } from "tiinvo"

export interface AboutPage {
  locale: string
  content: {
    hero: {
      title: string
      subtitle: string
    }
    actors: Actor[]
    seo: PageSeo
  }
}

type QueryType = {
  totenpassWebsiteContent: {
    pages: {
      team: AboutPage[]
    }
  }
}

export default function useAboutPage(): option.Option<AboutPage> {
  const intl = useIntl()
  const pagedata: QueryType = useStaticQuery(graphql`
    query Aboutpage {
      totenpassWebsiteContent {
        pages {
          team {
            locale
            content {
              hero {
                title
                subtitle
              }
              actors {
                image
                name
                role
                shortbio
                detailPageUrl
                longbioparagraphs
              }
              seo {
                description
                keywords
                title
              }
            }
          }
        }
      }
    }
  `)
  const data = pagedata.totenpassWebsiteContent.pages.team
  const foundbylocale = data.find((a) => a.locale === intl.locale)
  const foundbydefault = data.length > 0 ? data[0] : undefined

  return option.option(
    (foundbylocale ? foundbylocale : foundbydefault) as AboutPage
  )
}
