import useContactForm from "@hooks/use-contact-form"
import { FormHelperText, Grid, Input, TextField } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import React from "react"
import { ContactContainer } from "./ContactContainer"
import { ContactCTA } from "./ContactCTA"
import { ContactSuccessNotification } from "./ContactSuccessNotification"
import { ContactTitle } from "./ContactTitle"

const useTextAreaCSS = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        marginTop: 0,
      },
      "& .MuiFilledInput-root": {
        display: "flex",
        alignItems: "flex-start",
        border: "none",
        width: "100%",
        height: 130,
        backgroundColor: "#2E2E3B",
        color: "white",
        borderRadius: 6,
        padding: 20,
      },
    },
  })
)

const useCss = makeStyles((theme: CustomTheme) => ({
  formContainer: {
    marginTop: 40,
  },
  input: {
    width: "100%",
    height: 54,
    backgroundColor: "#2E2E3B",
    color: "white",
    borderRadius: 6,
    "&:after, &:before": {
      display: "none",
    },
  },
  textareaContainer: {
    marginTop: 20,
  },
  emailInputContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0 !important",
    },
  },
}))

export default function Contact() {
  const classes = useCss()
  const { form, sent } = useContactForm()
  const textareastyles = useTextAreaCSS()

  return (
    <ContactContainer>
      <ContactTitle />

      <form className={classes.formContainer}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Input
              className={classes.input}
              error={!!form.errors.name}
              name="name"
              inputProps={{ "aria-label": "Name" }}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Your Name"
              value={form.values.name}
            />
            {form.errors.name && (
              <FormHelperText error>{form.errors.name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.emailInputContainer}>
            <Input
              className={classes.input}
              error={!!form.errors.email}
              name="email"
              inputProps={{ "aria-label": "Email" }}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Your Email"
              value={form.values.email}
            />
            {form.errors.email && (
              <FormHelperText error>{form.errors.email}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.textareaContainer}>
          <Grid item xs={12} className={textareastyles.root}>
            <TextField
              aria-label="Your Message"
              error={!!form.errors.message}
              name="message"
              helperText={form.errors.message}
              multiline
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Your Message"
              rowsMax={4}
              value={form.values.message}
            />
          </Grid>
        </Grid>
        <ContactCTA
          disabled={!form.dirty || form.isSubmitting || !form.isValid}
          onSubmit={form.submitForm}
          submitting={form.isSubmitting}
        />
        <ContactSuccessNotification sent={sent} />
      </form>
    </ContactContainer>
  )
}
