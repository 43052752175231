import useCssUtil from "@hooks/use-css-util"
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import GoToAppButton from "./GoToAppButton"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    position: "relative",
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "calc(100% - 15vw)",
      backgroundImage: "linear-gradient(180deg, #F1EDE4 0%, #FFFDF9 100%)",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
      "&::after": {
        height: "100%",
      },
    },
  },
  innerContainer: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  ctaButton: {
    alignSelf: "center",
  },
}))

export default function CTABlock() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.container}>
      <Container maxWidth="sm" className={classes.innerContainer}>
        <Typography
          align="center"
          variant="h2"
          component="h2"
          className={cssUtil.mb15}
        >
          <FormattedMessage
            defaultMessage="Create Your Totenpass"
            id="components.CTABlock.title"
          />
        </Typography>
        <Typography
          align="center"
          variant="body1"
          color="textSecondary"
          className={cssUtil.mb30}
        >
          <FormattedMessage
            defaultMessage="Digital data and human cooperation are concomitant, powering the exchanges of goods and services society depends on each day. As The Economist recently predicted: The world’s most valuable resource is no longer oil, but data."
            id="components.CTABlock.description"
          />
        </Typography>
        <GoToAppButton
          variant="contained"
          color="primary"
          className={classes.ctaButton}
          size="large"
        >
          <FormattedMessage
            defaultMessage="Get Started"
            id="components.CTABlock.ctaLabel"
          />
        </GoToAppButton>
      </Container>
    </section>
  )
}
