import { BioDetailEntryList } from "@components/BioDetailEntryList"
import Contact from "@components/contact"
import ContentHero from "@components/ContentHero"
import CTABlock from "@components/CTABlock"
import Seo from "@components/Seo"
import useBioPage from "@hooks/use-bio-page"
import { Container } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as routes from "@routes"
import { CustomTheme } from "@theme"
import { defineMessages, navigate, useIntl } from "gatsby-plugin-intl"
import React, { FC } from "react"
import { option } from "tiinvo"
import { PageProps } from "gatsby"

export interface BioDetailProps extends PageProps {}

const messages = defineMessages({
  description: {
    defaultMessage:
      "Totenpass is a permanent digital storage drive made of solid gold. End your dependence on the cloud, the internet, energy sources, and destructible hardware. Preserve your precious data once and forever.",
    id: "components.BioDetail.description",
  },
  title: {
    defaultMessage: "Team",
    id: "components.BioDetail.title",
  },
})

const createIdSection = (route: string) => <div id={route.replace("/#", "")} />

const useCss = makeStyles((theme: CustomTheme) => ({
  gridContainer: {
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    [theme.breakpoints.up("md")]: {
      paddingTop: "80px",
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  bioImgContainer: {
    width: "370px",
    height: "300px",
    backgroundColor: "#E7E4DD",
    borderRadius: "10px",
    overflow: "hidden",
    marginBottom: "30px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: "30px",
      float: "left",
    },
  },
  image: {
    objectFit: `cover`,
    height: `100%`,
    width: `100%`,
  },
}))

export const BioDetail: FC<BioDetailProps> = ({ location }) => {
  const intl = useIntl()
  const classes = useCss()
  const data = useBioPage(location.pathname)

  if (option.isNone(data)) {
    navigate(routes.error404)
    return null
  }

  const actor = option.unwrap(data)

  return (
    <>
      <Seo
        description={intl.formatMessage(messages.description)}
        title={intl.formatMessage(messages.title)}
      />
      <ContentHero title={actor.name} description={actor.role} />
      <Container maxWidth="md" className={classes.gridContainer}>
        <div className={classes.bioImgContainer}>
          {!!actor.image && <img className={classes.image} src={actor.image} />}
        </div>
        <BioDetailEntryList actor={actor} />
      </Container>
      <CTABlock />
      {createIdSection(routes.contact)}
      <Contact />
    </>
  )
}
