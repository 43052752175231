import * as TActor from "@definitions/Actor"
import { option } from "tiinvo"
import useTeamPage from "./use-team-page"

export default function useBioPage(
  detailPageUrl: string
): option.Option<TActor.Actor> {
  const teamPage = useTeamPage()
  const createMapPage = option.map(
    (page: any) =>
      page.content.actors.find((a: any) =>
        detailPageUrl.includes(a.detailPageUrl)
      ) as TActor.Actor
  )

  return createMapPage(teamPage)
}
