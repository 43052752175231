import useCssUtil from "@hooks/use-css-util"
import { Container, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import classNames from "classnames"
import clsx from "clsx"
import React, { ReactNode } from "react"

const useCss = makeStyles((theme: CustomTheme) => ({
  hero: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    paddingTop: 60,
    minHeight: 320,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: "url(/images/pattern-bg.svg)",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 448,
    },
  },
  heroContentContainer: {
    position: "relative",
    zIndex: 1,
  },
  heroTitle: {
    color: theme.custom.lightHeadingTextColor,
  },
  heroDescription: {
    fontSize: "18px",
  },
}))

interface ContentHeroProps {
  description: ReactNode
  title: ReactNode
}

export default function ContentHero({ title, description }: ContentHeroProps) {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.hero}>
      <Container maxWidth="sm" className={classes.heroContentContainer}>
        <Typography
          align="center"
          variant="h1"
          component="h1"
          className={classNames(cssUtil.mb20, classes.heroTitle)}
        >
          {title}
        </Typography>
        <Typography
          align="center"
          component="div"
          variant="body1"
          className={clsx(cssUtil.mb30, classes.heroDescription)}
        >
          {description}
        </Typography>
      </Container>
    </section>
  )
}
